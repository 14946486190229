import create from "zustand";
import { persist } from "zustand/middleware";

export interface StoreValues {
  originSuburb?: {
    label: string;
    state: string;
    value: string;
  };
  originType?: string;
  destinationSuburb?: {
    label: string;
    state: string;
    value: string;
  };
  destinationType?: string;
  apartment?: {
    floor: number;
    stairs: boolean;
    lift: boolean;
    furnishing: string;
    bedrooms: string;
    livingAreas: string;
    rooms: string[];
    specialItems: string[];
  };
  house?: {
    furnishing: string;
    bedrooms: string;
    livingAreas: string;
    rooms: string[];
    specialItems: string[];
  };
  office?: {
    size?: string;
  };
  storage?: {
    size?: string;
  };
  preferredDate?: string;
  preferredTime?: string;
  contact?: {
    firstname: string;
    lastname: string;
    email: string;
    company?: string;
    phone: string;
  };
}

const INITIAL_STATE: StoreValues = {};

const formStore = create(
  persist(
    (set, get) => ({
      data: INITIAL_STATE,
      set: (data: Partial<StoreValues>) => {
        console.debug("FORM_STORE: setting state", data);
        const newData = { ...(get() as any).data, ...data };
        set({ data: newData });
      },
      clear: () => {
        console.debug("FORM_STORE: clearing state");
        set({ data: INITIAL_STATE });
      },
    }),
    {
      name: "form-store",
      getStorage: () => sessionStorage,
      // deserialize: (str) => JSON.parse(atob(str)),
      // serialize: (state) => btoa(JSON.stringify(state)),
      version: 1000,
    }
  )
);

export const STORE = formStore as any;

export function useFormStore() {
  return formStore(
    ({ set, clear, data }) => ({ state: data, set, clear } as const)
  );
}
