import cookies from "js-cookie";

type Options = {
  prefix?: string;
  domain?: string;
  ttlDays?: number;
};

export function save(params: string[], options: Options = {}) {
  if (!Array.isArray(params)) {
    throw new Error("expected params to be an array");
  }
  const prefix = options.prefix || "qc_";
  const expires = new Date();
  expires.setDate(new Date().getDate() + (options.ttlDays ?? 90));

  const query = new URLSearchParams(window.location.search);
  params
    .filter((key) => typeof query.get(key) === "string")
    .forEach((param) => {
      const value = query.get(param);
      const key = prefix + param;
      if (value !== null) {
        console.debug("saving query param to cookie: ", `${key}=${value}`);
        cookies.set(key, value, {
          domain: options.domain,
          expires,
          sameSite: "Strict",
        });
      }
    });
}

export function read(params: string[], options: Options = {}) {
  const prefix = options.prefix || "qc_";
  const expires = new Date();
  expires.setDate(new Date().getDate() + (options.ttlDays ?? 90));

  const data: Record<string, string> = {};

  params.forEach((param) => {
    const key = prefix + param;
    const value = cookies.get(key);
    console.log("reading cookie value", { key, value });
    if (value !== undefined) {
      data[param] = value;
    }
  });
  return data;
}

export const params = {
  GOOGLE_CLICK_ID: "gclid",
  FACEBOOK_ID: "fbid",
  UTM_SOURCE: "utm_source",
  UTM_MEDIUM: "utm_medium",
  UTM_CAMPAIGN: "utm_campaign",
  UTM_TERM: "utm_term",
  UTM_CONTENT: "utm_content",
};
