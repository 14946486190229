import { NextWebVitalsMetric } from "next/app";
import TagManager from "react-gtm-module";

export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;

if (typeof window !== "undefined") {
  TagManager.initialize({
    gtmId: GTM_ID,
  });
}

type GTagEvent = {
  action: string;
  category: string;
  label: string;
  value: number;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: GTagEvent): void => {
  if (!window.gtag) return;
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value,
  });
};

export function reportMetric({ id, name, label, value }: NextWebVitalsMetric) {
  if (!window.gtag) return;
  // Use `window.gtag` if you initialized Google Analytics as this example:
  // https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_app.js
  window.gtag("event", name, {
    event_category:
      label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
    value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
    event_label: id, // id unique to current page load
    non_interaction: true, // avoids affecting bounce rate.
  });
}
