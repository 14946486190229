import React, { InputHTMLAttributes, useRef } from "react";
import {
  useNumberInput,
  HStack,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  InputProps,
} from "@chakra-ui/react";

interface Props {
  onChange: (value: number) => void;
}

export const NumberStepperInput: React.FC<Props & InputProps> = ({
  onChange,
  onBlur,
  onFocus,
  ...props
}) => {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      // @ts-expect-error
      defaultValue: props.defaultValue,
      // @ts-expect-error
      value: props.value,
      min: 0,
      max: 100,
      precision: 0,
      focusInputOnChange: true,
      onBlur: onChange,
      onChange: (_, value) => onChange(value),
      onFocus: onFocus,
    });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps({ readOnly: true, ...props });

  return (
    <InputGroup>
      <InputLeftElement>
        <Button
          size="sm"
          variant="ghost"
          colorScheme="brand"
          fontSize="xl"
          lineHeight={1}
          {...dec}
        >
          -
        </Button>
      </InputLeftElement>
      <Input textAlign="center" {...input} />
      <InputRightElement>
        <Button
          size="sm"
          variant="ghost"
          colorScheme="brand"
          fontSize="xl"
          lineHeight={1}
          {...inc}
        >
          +
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};
