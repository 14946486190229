import { useRouter } from "next/router";
import { useEffect, useRef } from "react";

declare global {
  interface Window {
    _hsq: any[];
  }
}

export const _hsq = (globalThis._hsq = globalThis._hsq || []);

export function identify(email: string) {
  _hsq.push(["identify", { email }]);
}

export function trackPageView(path: string) {
  _hsq.push(["setPath", path]);
  _hsq.push(["trackPageView"]);
}

export function useSubscribeHubspotToRouter() {
  let mounted = useRef(false);
  const router = useRouter();

  // Initial page view
  useEffect(() => {
    if (mounted.current) return;
    mounted.current = true;
    _hsq.push(["setPath", router.asPath]);
  }, [mounted, router]);

  useEffect(() => {
    // Subscribe to router events:
    const handleRouteChange = (path) => {
      trackPageView(path);
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);
}
