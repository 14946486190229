import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";

export const colors = {
  brand: {
    50: "#dbfaff",
    100: "#b2e8fc",
    200: "#87d7f5",
    300: "#5bc6ef",
    400: "#30b6e9",
    500: "#169ccf",
    600: "#0579a2",
    700: "#005775",
    800: "#003549",
    900: "#00131d",
  },
  accent: {
    50: "#ebfee1",
    100: "#d0f6b8",
    200: "#b3ef8e",
    300: "#95e862",
    400: "#78e237",
    500: "#5ec81d",
    600: "#489c15",
    700: "#326f0c",
    800: "#1c4404",
    900: "#041800",
  },
  // Old accent colour orange:
  // accent: {
  //   100: "#ffeddb",
  //   200: "#ffceae",
  //   300: "#ffaf7e",
  //   400: "#ff904c",
  //   500: "#ff7b2b",
  //   600: "#e65700",
  //   700: "#b44200",
  //   800: "#812f00",
  //   900: "#4f1b00",
  // },
};

export const theme = extendTheme(
  {
    styles: {
      global: {
        "*": {
          fontVariantNumeric: "lining-nums",
        },
      },
    },
    colors,
    fonts: {
      heading: '"freight-sans-pro","Source Sans Pro", sans-serif',
      body: '"freight-sans-pro", "Source Sans Pro", sans-serif',
    },
    components: {
      FormLabel: {
        baseStyle: {
          fontWeight: 600,
        },
      },
      Input: {
        defaultProps: {
          // variant: "filled",
          focusBorderColor: "brand.500",
        },
      },
      Select: {
        defaultProps: {
          // variant: "filled",
          focusBorderColor: "brand.500",
        },
      },
      Switch: {
        baseStyle: {
          focusBorderColor: "brand.500",
        },
      },
      Checkbox: {
        defaultProps: {
          focusBorderColor: "brand.500",
        },
      },
    },
  },
  withDefaultColorScheme({ colorScheme: "accent" })
);
