import { ChakraProvider } from "@chakra-ui/react";
import { NextPage } from "next";
import { AppProps, NextWebVitalsMetric } from "next/app";
import Head from "next/head";
import { ReactElement, ReactNode, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { theme } from "ui";
import { reportMetric } from "../utils/gtag";
import * as Sentry from "@sentry/nextjs";
import * as QC from "query-cookie";
import { useSubscribeHubspotToRouter } from "../utils/hubspot-tracking";
import { STORE } from "../utils/form-store";
import { MetaPixel } from "../components/MetaPixel";

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => any;
};

type AppPropsWithLayout = AppProps & {
  Component: any; // NextPageWithLayout
  err: any;
};

const queryClient = new QueryClient();

function App({ Component, pageProps, err }: AppPropsWithLayout) {
  useSubscribeHubspotToRouter();
  useEffect(() => {
    QC.read(["gclid", "fbid"]);
  }, []);
  const getLayout = Component.getLayout ?? ((page) => page);
  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
        <meta charSet="utf-8" />
        <title>Book | Holloway Removals & Storage</title>
        <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#ff7b2b" />
        <meta name="env" content={process.env.NEXT_PUBLIC_VERCEL_ENV} />
        <meta
          name="commit-sha"
          content={process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA}
        />
      </Head>
      <MetaPixel />
      <QueryClientProvider client={queryClient}>
        <ChakraProvider resetCSS theme={theme}>
          {getLayout(<Component {...pageProps} err={err} />)}
        </ChakraProvider>
      </QueryClientProvider>
    </>
  );
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  if (process.env.NODE_ENV !== "production") {
    console.debug("web vitals", metric);
  }
  reportMetric(metric);
}

// Try add booking state to event
Sentry.addGlobalEventProcessor((event) => {
  try {
    event.extra = event.extra || {};
    const formState = STORE.getState();
    if (formState) event.extra.formState = formState;
  } catch (error) {
    console.log("Error adding booking state to event", error);
  }
  return event;
});

export default App;
